export const TRANSACTION_ACTIONS = [
  'BEGIN TRANSACTION',
  'COMMIT TRANSACTION',
  'ROLLBACK TRANSACTION'
]

export const REST_ACTIONS = ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']

export const OAUTH_ACTIONS = ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']

export const MONGODB_ACTIONS = ['FIND', 'INSERT', 'UPDATE', 'DELETE', 'COUNT']

export const JDBC_ACTIONS = [
  'SELECT',
  'UPDATE',
  'INSERT',
  'DELETE',
  'CUSTOM',
  'BEGIN TRANSACTION',
  'COMMIT TRANSACTION',
  'ROLLBACK TRANSACTION'
]

export const EXECUTE_PROCESS_ACTIONS = ['ASYNC', 'SYNC', 'INLINE']

export const USER_ACTIONS = [
  'CHECK_EMAIL_AVAILABILITY',
  'CREATE_USER',
  'GENERATE_API_KEY',
  'VALIDATE_API_KEY',
  'VALIDATE_PASSWORD',
  'GET_USER_DETAILS',
  'GET_USER_ROLES',
  'GET_USERS_BY_ROLES'
].sort()

export const S3_ACTIONS = ['CREATE', 'DELETE', 'LIST', 'READ']

export const SECURITY_ACTIONS = ['DECRYPT', 'ENCRYPT']

export const ENCRYPT_METHODS = ['AES', 'HMAC_SHA256']

export const DECRYPT_METHODS = ['AES']

export const IMAP_ACTIONS = ['DELETE', 'MARK_READ', 'MARK_UNREAD', 'SEARCH', 'ATTACHMENTS'].sort()

export const CSV_ACTIONS = ['CSV_TO_JSON', 'JSON_TO_CSV'].sort()

export const IMAGE_ACTIONS = ['RESCALE', 'DOWNLOAD', 'CONVERT', 'INFO', 'FROM_TEMPLATE'].sort()

export const PAYMENT_SENSE_PAC_ACTIONS = ['CANCEL_TRANSACTION', 'GET_REPORT', 'GET_TERMINAL', 'GET_TERMINALS', 'GET_TRANSACTION', 'SIGNATURE', 'START_REPORT', 'START_TRANSACTION']

export const PAYMENT_SENSE_PAC_REPORT_TYPES = ['BANKING', 'END_OF_DAY', 'X_BALANCE', 'Z_BALANCE']

export const PAYMENT_SENSE_CURRENCIES = ['EUR', 'GBP']

export const PAYMENT_SENSE_PAC_TRANSACTION_TYPES = ['ACCOUNT_VERIFICATION', 'COMPLETION', 'DUPLICATE', 'PRE_AUTH', 'REFUND', 'SALE']

export const TRANSACTION_SOURCES = ['MOTO']

export const TRANSACTION_TYPES = ['COLLECTION', 'PREAUTH', 'REFUND', 'SALE', 'VOID']

export const PAYMENT_SENSE_CONNECT_E_ACTIONS = ['ACCESS_TOKEN', 'CROSS_REFERENCE_PAYMENT', 'PAYMENT_DETAILS', 'PAYMENT_METHODS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN']

export const FTP_ACTIONS = ['LIST', 'DOWNLOAD', 'UPLOAD', 'DELETE', 'MAKE_DIRECTORY', 'RENAME']

export const CREDENTIAL_ACTIONS = ['CREATE', 'UPDATE', 'ENABLE', 'DISABLE']

export const ZIP_ACTIONS = ['COMPRESS', 'DECOMPRESS']

export const STORAGE_ACTIONS = ['GET', 'UPSERT']

export const FORMATTING_ACTIONS = ['DECIMAL_FORMAT']

export const XML_ACTIONS = ['JSON_TO_XML', 'XML_TO_JSON']

export const CLOUDFRONT_ACTIONS = ['CREATE', 'LIST', 'UPDATE', 'DELETE']

export const OUTLOOK_CALENDAR_ACTIONS = ['CREATE', 'LIST', 'UPDATE', 'DELETE', 'AUTH']

export const PDF_ACTIONS = ['GENERATE', 'GET_PAGE_COUNT', 'GET_PAGE_AS_PICTURE', 'GET_TEXT']

export const TIMER_ACTIONS = ['START', 'END']

export const CONTENT_TYPES = [
  '\'application/json\'',
  '\'application/xml\'',
  '\'application/x-www-form-urlencoded\'',
  '\'multipart/form-data\''
]

export const EMAIL_TYPES = ['ASYNC', 'SYNC']

export const READ_TYPES = ['ALL', 'READ', 'UNREAD']

export const OUTLOOK_CALENDAR_CONTENT_TYPES = ['TEXT', 'HTML']

export const SCRIPT_ENGINES = ['JS', 'GROOVY', 'PYTHON']

export const DEFAULT_SCRIPT_ENGINE = 'JS'

export const QUERY_DELIMETERS = [',', 'AND', 'OR']

export const AUTH_TYPES = ['NONE', 'BASIC', 'BEARER']

export const MESSAGING_SERVICE_TYPES = ['KAFKA', 'JMS', 'RABBITMQ', 'MQTT']

export const TIME_UNITS = ['MSec', 'Sec', 'Min', 'Hr', 'Day']

export const DEFAULT_TIME_UNIT = 'Sec'
