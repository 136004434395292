<template>
  <v-card class="pa-2" flat>
    <v-row class="mx-0 pb-1 align-center" justify="space-between" style="height: 100px">
      <span>{{ $lang.status.EXECUTE_PROCESS }}</span>
      <div class="d-inline-flex">
        <v-autocomplete
          v-model="customJSFunctionsValue"
          :items="customJSFunctions"
          :label="$lang.labels.customFunctions"
          outlined
          dense
          hide-details
          hide-no-data
          hide-selected
          auto
          style="max-width: 300px"
          class="ml-2"
          @change="addShortCode(customJSFunctionsValue)"
        />
        <v-autocomplete
          v-model="valueArrayValue"
          :items="valueArray"
          item-text="key"
          item-value="key"
          label="Variables"
          outlined
          dense
          hide-details
          hide-no-data
          hide-selected
          auto
          style="max-width: 300px"
          class="ml-2"
          @change="addShortCode(valueArrayValue)"
        />
        <v-btn
          icon
          color="primary"
          class="ml-2"
          @click="$emit('closeDialog', true)"
        >
          <v-icon dark size="20" >mdi-close</v-icon>
        </v-btn>
      </div>
    </v-row>
    <p class="pb-0 mb-1">{{ $lang.header.key }}</p>
    <v-combobox
      ref="keyRef"
      v-model="key"
      outlined
      dense
      required
      :items="keyList"
      item-text="name"
      item-value="name"
      :readonly="!canEdit"
      class="required-asterisk"
      data-cy="execute-process-key"
      :rules="[
        (v => !!v || $lang.labels.required),
        ((v) => (!!v && $options.filters.javaVariableConventionRules(
          typeof v === 'object' ? v.name : v, true)) || $lang.errors.variableJavaWrong
        )
      ]"
      :search-input.sync="key"
    />
    <p class="pb-0 mb-0">{{ $lang.header.value }}</p>
    <codemirror v-if="initDone" v-model="code" :options="cmOptions" @blur="(e) => handleBlurEditor(e, 0)"></codemirror>
    <v-card-title v-if="withButtons" class="pt-2">
      <v-btn
        color="primary"
        text
        class="ml-1"
        min-width="100px"
        min-height="40px"
        @click="$emit('closeDialog', true)"
      >
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        class="mr-1"
        min-width="100px"
        min-height="40px"
        :disabled="!key || !code || !$options.filters.javaVariableConventionRules(key.name, true)"
        @click="save()"
      >
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
// require component
import { codemirror } from 'vue-codemirror'
import { JSHINT } from 'jshint'

// require styles
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'

// import js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'

import { defaultRestVariables } from '@/utils/constants'
import { castValue, recursion } from '@/utils/helpers'

export default {
  components: {
    codemirror
  },
  props: {
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {
          text: '',
          value: ''
        }
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withButtons: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    keyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => (
    {
      insertIndex: 0,
      initDone: false,
      code: '',
      key: '',
      customJSFunctions: [
        'f_is_var_defined',
        'f_is_var_defined_and_not_null',
        'f_check_defined_and_not_null_default'
      ],
      cursorPosition: {
        line: 0,
        ch: 0
      },
      valueArray: [],
      customJSFunctionsValue: '',
      customFunctionsValue: '',
      valueArrayValue: '',
      cmOptions: {
        gutters: [],
        tabSize: 4,
        mode: 'python',
        theme: 'ayu-mirage',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        selfContain: false,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true,
        lint: false,
        showHints: true
      }
    }),
  watch: {
    code: {
      handler(val) {
        if (!this.withButtons) this.$emit('fromGlobalEditor', val)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.item && !this.item.text) this.$refs.keyRef.focus()
    }, 200)
  },
  created() {
    window.JSHINT = JSHINT
    if (this.item) {
      this.code = this.item.value ? typeof this.item.value === 'string' ? this.item.value : JSON.stringify(this.item.value) : ''
      this.key = this.item.text
    }

    this.valueArray = this.recursion(this.vars, this.singleStep)

    const names = this.valueArray.map((o) => o.key)
    const filtered = this.valueArray.filter(({ key }, index) => !names.includes(key, index + 1))

    this.valueArray = filtered.sort((a, b) => a.key.localeCompare(b.key))

    this.valueArray = [...this.valueArray, ...defaultRestVariables]

    if (this.$vuetify.theme.dark) {
      this.cmOptions.theme = 'ayu-mirage'
    } else {
      this.cmOptions.theme = '3024-day'
    }

    setTimeout(() => {
      this.initDone = true
    }, 300)
  },
  methods: {
    recursion,
    handleBlurEditor(data, i) {
      this.insertIndex = i
      const doc = data.getDoc()

      const cursor = doc.getCursor()

      const pos = {
        line: cursor.line,
        ch: cursor.ch
      }

      this.cursorPosition = pos
    },
    addShortCode(value) {
      const cm = document.querySelectorAll('.CodeMirror')[this.insertIndex].CodeMirror

      const position = this.cursorPosition.ch === 0 || this.cursorPosition ? this.cursorPosition : this.code.length

      const doc = cm.getDoc()

      doc.replaceRange(value, position) // adds a new line

      setTimeout(() => {
        this.customJSFunctionsValue = null
        this.customFunctionsValue = ''
        this.valueArrayValue = ''
      }, 5)
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromGlobalEditor', {
        key: typeof this.key === 'object' ? this.key.name : this.key,
        value: this.code
      })
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 50vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
